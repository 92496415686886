import { withDependencies, optional } from '@wix/thunderbolt-ioc'
import { SdkHandlersProvider } from '@wix/thunderbolt-symbols'
import { INavigation, NavigationSymbol } from 'feature-navigation'
import { IUrlHistoryManager, UrlHistoryManagerSymbol, IUrlChangeHandler } from 'feature-router'
import { CustomUrlMapperSymbol, ICustomUrlMapper, ICustomUrlLocationHandler } from 'feature-custom-url-mapper'

export const locationWixCodeSdkHandlersProvider = withDependencies(
	[NavigationSymbol, UrlHistoryManagerSymbol, optional(CustomUrlMapperSymbol)],
	(
		navigation: INavigation,
		urlHistoryManager: IUrlHistoryManager,
		customUrlMapper: ICustomUrlMapper | undefined
	): SdkHandlersProvider<Record<string, Function> & ICustomUrlLocationHandler> & IUrlChangeHandler => {
		const onChangeHandlers: Array<Function> = []

		return {
			getSdkHandlers: () => ({
				navigateTo: navigation.navigateTo,
				pushUrlState: (href: string): void => {
					const url = new URL(href)
					urlHistoryManager.pushUrlState(url)
				},
				registerLocationSdkOnChangeHandler: (handler: Function) => {
					onChangeHandlers.push(handler)
				},
				buildUrl: async (key, itemData, options) => {
					const urlMappings = customUrlMapper?.urlMappings

					const { buildUrl } = await import(
						'@wix/url-mapper-utils' /* webpackChunkName: "url-mapper-utils" */
					)

					return buildUrl(urlMappings, key, itemData, options)
				},
			}),
			onUrlChange: async (url) => {
				onChangeHandlers.forEach((handler) => handler(url.href))
			},
		}
	}
)
