import { optional, withDependencies } from '@wix/thunderbolt-ioc'
import { SdkHandlersProvider, DynamicPagesSymbol } from '@wix/thunderbolt-symbols'
import { DynamicPagesAPI, FetchParams } from 'feature-router'
import { CustomUrlMapperSymbol, ICustomUrlMapper } from 'feature-custom-url-mapper'
import { ISdkHanlder } from '../types'

export const siteSdkProvider = withDependencies(
	[optional(DynamicPagesSymbol), optional(CustomUrlMapperSymbol)],
	(
		dynamicPagesAPI: DynamicPagesAPI,
		customUrlMapper: ICustomUrlMapper | undefined
	): SdkHandlersProvider<ISdkHanlder> => ({
		getSdkHandlers: () => {
			return {
				getSitemapFetchParams: (routePrefix: string): FetchParams | null => {
					if (!dynamicPagesAPI) {
						return null
					}

					return dynamicPagesAPI.getSitemapFetchParams(routePrefix)
				},
				getUrlSegments: async (url, options) => {
					const urlMappings = customUrlMapper?.urlMappings

					const { getUrlSegments } = await import(
						'@wix/url-mapper-utils' /* webpackChunkName: "url-mapper-utils" */
					)

					return getUrlSegments(urlMappings, url, options)
				},
			}
		},
	})
)
